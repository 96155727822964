<template>
  <div>
    <!-- begin::Main modal -->
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      scrollable
      max-width="600"
      persistent
    >
      <v-form>
        <v-card>
          <v-toolbar dark color="pink" class="elevation-0 px-3">
            <span class="headline"
              >Grupos en los que se impartirá la asignatura</span
            >
          </v-toolbar>
          <v-card-text>
            <!-- begin::Subject info -->
            <v-container>
              <v-row v-if="Object.keys(subject).length > 0">
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Asignatura:</div>
                    <span class="text-h6 font-weight-medium">{{
                      subject.name
                    }}</span>
                  </div>
                </v-col>
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Tipo de asignatura:</div>
                    <span class="text-h6 font-weight-medium">{{
                      `${subject.subject_evaluation_scale.subject_type}, ${subject.subject_evaluation_scale.subject_evaluation_methodology}`
                    }}</span>
                  </div>
                </v-col>
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Grado:</div>
                    <span class="text-h6 font-weight-medium">{{
                      subject.grade ? subject.grade : ""
                    }}</span>
                  </div>
                </v-col>
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Especialidad:</div>
                    <span class="text-h6 font-weight-medium">{{
                      subject.speciality ? subject.speciality : ""
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <!-- end::Subject info -->
            <v-divider class="mx-4 my-0"></v-divider>
            <v-container>
              <!-- begin::Academic groups List -->
              <v-row>
                <v-col class="pb-0">
                  <span class="text-h6">Grupos académicos</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-list
                    v-for="(academicGroups, section) in gradeAcademicGroups"
                    dense
                    :key="`section-${section}`"
                  >
                    <span class="text-body-2">Secciones "{{ section }}"</span>
                    <v-list-item-group color="pink">
                      <v-list-item
                        v-for="academicGroup in academicGroups"
                        :key="`aG-${academicGroup.id}`"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="academicGroup.name"
                            class="text-body-1"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn
                            depressed
                            small
                            @click="
                              selectAcademicGroup(academicGroup);
                              toggleTeacherAcademicGroupModal();
                            "
                          >
                            Ver
                            <v-icon color="pink" right>
                              mdi-eye-outline
                            </v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
              <!-- end::Academic groups List -->
              <!-- begin::Technical groups List -->
              <v-row v-if="technicalGroups.length > 0">
                <v-col class="pb-0">
                  <span class="text-h6">Grupos técnicos</span>
                </v-col>
              </v-row>
              <v-row v-if="technicalGroups.length > 0">
                <v-col>
                  <v-list-item-group color="pink">
                    <v-list-item
                      v-for="technicalGroup in technicalGroups"
                      :key="`tG-${technicalGroup.id}`"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text-body-2">
                          {{ `${technicalGroup.speciality.name} ` }}
                          <span class="text-body-1 font-weight-medium">
                            {{ `Grupo ${technicalGroup.groups.name}` }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          depressed
                          small
                          @click="
                            selectTechnicalGroup(technicalGroup);
                            toggleTeacherTechnicalGroupModal();
                          "
                        >
                          Ver
                          <v-icon color="pink" right> mdi-eye-outline </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-col>
              </v-row>
              <!-- end::Technical groups List -->
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- end::Main modal -->
    <!-- begin::Academic Group modal -->
    <v-dialog
      v-model="asignarDocenteGruposAcademicosDialog"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      max-width="550"
    >
      <v-form>
        <v-card>
          <v-toolbar dark color="pink accent-4" class="elevation-0 px-3">
            <span class="headline"
              >Asignar docente que impartirá la asignatura al grupo
              académico</span
            >
          </v-toolbar>
          <v-card-text>
            <v-container>
              <!-- begin::Subject info -->
              <v-row>
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Asignatura:</div>
                    <span class="text-h6 font-weight-medium">{{
                      subject.name
                    }}</span>
                  </div>
                </v-col>
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Sección:</div>
                    <span class="text-h6 font-weight-medium">{{
                      selectedAcademicGroup.name
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <!-- end::Subject info -->
            </v-container>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-container>
              <v-row>
                <v-col>
                  <span class="text-body-1"
                    >Selecciona el docente al que se le asignará la carga de la
                    asignatura y sección seleccionadas</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h6">Docentes</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span
                    class="text-body-1 text-center"
                    v-if="teachers.length == 0"
                    >No hay docentes registrados</span
                  >
                  <span
                    class="text-body-1 text-center"
                    v-else-if="subjectTeachers.length == 0"
                    >No hay docentes asignados</span
                  >
                  <span v-if="isLoadingTeacherGroupsOfAcademicGroups"
                    >Cargando asignaciones de grupo académico...
                  </span>
                  <v-list :disabled="isLoadingTeacherGroupsOfAcademicGroups">
                    <v-list-item-group v-model="subjectTeacherId">
                      <template v-for="item in subjectTeachers">
                        <v-list-item
                          :key="`item-${item.id}`"
                          :value="item.id"
                          active-class="pink--text text--accent-4"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-avatar>
                              <v-avatar
                                color="pink accent-4"
                                size="40"
                                class="white--text"
                              >
                                {{
                                  teacherData(item.user_id).first_name[0] +
                                  teacherData(item.user_id).last_name[0]
                                }}
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{
                                  `${teacherData(item.user_id).first_name} ${
                                    teacherData(item.user_id).last_name
                                  }`
                                }}
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="pink accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn
              text
              outlined
              class="mr-1"
              @click="closeTeacherAcademicGroupModal"
              >Cerrar</v-btn
            >
            <v-btn
              color="pink accent-4"
              depressed
              dark
              :loading="isLoading"
              @click="saveTeacherGroupOfAcademicGroup"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- end::Academic Group modal -->
    <!-- begin::Technical Group modal -->
    <v-dialog
      v-model="asignarDocentesGruposTecnicosDialog"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      max-width="550"
    >
      <v-form>
        <v-card>
          <v-toolbar dark color="pink accent-4" class="elevation-0 px-3">
            <span class="headline"
              >Asignar docente que impartirá la asignatura al grupo
              técnico</span
            >
          </v-toolbar>
          <v-card-text>
            <v-container>
              <!-- begin::Subject info -->
              <v-row>
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Asignatura:</div>
                    <span class="text-h6 font-weight-medium">{{
                      subject.name
                    }}</span>
                  </div>
                </v-col>
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Especiliadad:</div>
                    <span class="text-h6 font-weight-medium">{{
                      selectedTechnicalGroup.speciality
                        ? selectedTechnicalGroup.speciality.name
                        : ""
                    }}</span>
                  </div>
                </v-col>
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Grupo:</div>
                    <span class="text-h6 font-weight-medium">{{
                      `Grupo ${
                        selectedTechnicalGroup.groups
                          ? selectedTechnicalGroup.groups.name
                          : ""
                      }`
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <!-- end::Subject info -->
            </v-container>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-container>
              <v-row>
                <v-col>
                  <span class="text-h6">Docentes</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span
                    class="text-body-1 text-center"
                    v-if="teachers.length == 0"
                    >No hay docentes registrados</span
                  >
                  <span v-if="isLoadingTeacherGroupsOfAcademicGroups"
                    >Cargando asignaciones de grupo técnico...
                  </span>
                  <v-list :disabled="isLoadingTeacherGroupsOfTechnicalGroups">
                    <v-list-item-group v-model="subjectTeacherId">
                      <template v-for="item in subjectTeachers">
                        <v-list-item
                          :key="`item-${item.id}`"
                          :value="item.id"
                          active-class="pink--text text--accent-4"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-avatar>
                              <v-avatar
                                color="pink accent-4"
                                size="40"
                                class="white--text"
                              >
                                {{
                                  teacherData(item.user_id).first_name[0] +
                                  teacherData(item.user_id).last_name[0]
                                }}
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{
                                  `${teacherData(item.user_id).first_name} ${
                                    teacherData(item.user_id).last_name
                                  }`
                                }}
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="pink accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn
              text
              outlined
              class="mr-1"
              @click="closeTeacherTechnicalGroupModal"
              >Cerrar</v-btn
            >
            <v-btn
              color="pink accent-4"
              depressed
              dark
              :loading="isLoading"
              @click="saveTeacherGroupOfTechnicalGroup"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- end::Technical Group modal -->
  </div>
</template>

<script>
import academicGroupRepository from "@/repositories/academicGroupRepository";
import subjectTeacherRepository from "@/repositories/subjectTeacherRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import { FETCH_USERS } from "@/core/services/store/role.module.js";

export default {
  name: "SubjectAcademicGroupsListModal",
  data() {
    return {
      isLoading: false,
      dialog: false,
      gradeAcademicGroups: {},
      selectedAcademicGroup: {},
      technicalGroups: [],
      asignarDocenteGruposAcademicosDialog: false,
      subjectTeachers: [],
      subjectTeacherId: null,
      teacherGroupId: null,
      asignarDocentesGruposTecnicosDialog: false,
      selectedTechnicalGroup: {},
      teacherGroupsOfAcademicGroups: [],
      teacherGroupsOfTechnicalGroups: [],
      isLoadingTeacherGroupsOfAcademicGroups: false,
      isLoadingTeacherGroupsOfTechnicalGroups: false,
    };
  },
  props: {
    subject: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    toggleTeacherAcademicGroupModal() {
      this.asignarDocenteGruposAcademicosDialog =
        !this.asignarDocenteGruposAcademicosDialog;
    },

    toggleTeacherTechnicalGroupModal() {
      this.asignarDocentesGruposTecnicosDialog =
        !this.asignarDocentesGruposTecnicosDialog;
    },

    closeModal() {
      this.toggleModal();
      this.gradeAcademicGroups = [];
      this.technicalGroups = [];
      this.teacherGroupsOfAcademicGroups = [];
      this.teacherGroupsOfTechnicalGroups = [];
    },

    closeTeacherAcademicGroupModal() {
      this.toggleTeacherAcademicGroupModal();
      this.subjectTeachers = [];
      this.subjectTeacherId = null;
      this.teacherGroupId = null;
      this.selectedAcademicGroup = {};
    },

    closeTeacherTechnicalGroupModal() {
      this.toggleTeacherTechnicalGroupModal();
      this.subjectTeachers = [];
      this.subjectTeacherId = null;
      this.teacherGroupId = null;
      this.selectedTechnicalGroup = {};
    },

    saveTeacherGroupOfAcademicGroup() {
      this.isLoading = true;
      const payload = {
        subject_teacher_id: this.subjectTeacherId,
        teacher_group_id: this.teacherGroupId,
      };
      academicGroupRepository
        .updateOrCreateTeacherGroup(this.selectedAcademicGroup.id, payload)
        .then(({ data }) => {
          // Showing alert with response data
          this.fireToast({
            icon: data.status ? "success" : "error",
            title: data.message,
          });
          // Hidding modal if operation has been completed
          if (data.status) {
            // Se obtienen los teacher_groups otra vez
            this.getTeacherGroupsOfAcademicGroups(
              this.selectedAcademicGroup.id
            );
            this.closeTeacherAcademicGroupModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible guardar los registros 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    saveTeacherGroupOfTechnicalGroup() {
      this.isLoading = true;
      const payload = {
        subject_teacher_id: this.subjectTeacherId,
        teacher_group_id: this.teacherGroupId,
      };
      technicalGroupRepository
        .updateOrCreateTeacherGroup(this.selectedTechnicalGroup.id, payload)
        .then(({ data }) => {
          // Showing alert with response data
          this.fireToast({
            icon: data.status ? "success" : "error",
            title: data.message,
          });
          // Hidding modal if operation has been completed
          if (data.status) {
            // Se obtienen los teacher_groups otra vez
            this.getTeacherGroupsOfTechnicalGroups(
              this.selectedTechnicalGroup.id
            );
            this.closeTeacherTechnicalGroupModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible guardar los registros 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getAcademicGroupsOfGrade(id) {
      academicGroupRepository
        .getAcademicGroupsByGrade(id)
        .then(({ data }) => {
          // Agrupa los grupos academicos por secciones en un objecto donde la key es la sección y el valor es un array con todos los grupos academicos
          const dataGroupedBySection = data.reduce((accumulator, current) => {
            if (!accumulator[current.section]) {
              accumulator[current.section] = [];
            }
            accumulator[current.section].push({
              id: current.id,
              name: `${current.section}-${current.group}`,
            });
            return accumulator;
          }, {});
          this.gradeAcademicGroups = dataGroupedBySection;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    getTechnicalGroupsOfGrade(id) {
      technicalGroupRepository
        .getTechnicalGroupsByGrade(id)
        .then(({ data }) => {
          //if is tercer ciclo
          if (id < 4) {
            this.technicalGroups = data;
            return;
          }

          // if not
          const filteredData = data.filter(
            (item) => item.speciality_id == this.subject.speciality_id
          );
          this.technicalGroups = filteredData;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    getSubjectTeachersOfSubject(id) {
      subjectTeacherRepository
        .getSubjectTeachersBySubject(id)
        .then(({ data }) => {
          this.subjectTeachers = data;
          if (this.asignarDocenteGruposAcademicosDialog) {
            this.setSubjectTeacherOfAcademicGroup();
          }
          if (this.asignarDocentesGruposTecnicosDialog) {
            this.setSubjectTeacherOfTechnicalGroup();
          }
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    getTeacherGroupsOfAcademicGroups(id) {
      this.isLoadingTeacherGroupsOfAcademicGroups = true;
      academicGroupRepository
        .getTeacherGroupOfGrade(id)
        .then(({ data }) => {
          const { teacher_groups } = data;
          this.teacherGroupsOfAcademicGroups = teacher_groups;
          this.getSubjectTeachersOfSubject(this.subject.id);
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.isLoadingTeacherGroupsOfAcademicGroups = false;
        });
    },

    getTeacherGroupsOfTechnicalGroups(id) {
      this.isLoadingTeacherGroupsOfTechnicalGroups = true;
      technicalGroupRepository
        .getTeacherGroupOfGrade(id)
        .then(({ data }) => {
          const { teacher_groups } = data;
          this.teacherGroupsOfTechnicalGroups = teacher_groups;
          this.getSubjectTeachersOfSubject(this.subject.id);
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.isLoadingTeacherGroupsOfTechnicalGroups = false;
        });
    },

    setSubjectTeacherOfAcademicGroup() {
      // Busca el registro que tenga la llave foranea del grupo academico seleccionado y que el subject_teacher coincida con alguno de los de la asignatura
      const teacherGroup = this.teacherGroupsOfAcademicGroups.find(
        (item) =>
          item.teacher_groupable_id == this.selectedAcademicGroup.id &&
          this.subjectTeachers.some(
            (element) => item.subject_teacher_id == element.id
          )
      );

      if (teacherGroup) {
        // Si hay un registro, guarda su id y  busca el subject_teacher en los maestros que imparten la asignatura que coincida con el del registro obtenido
        this.teacherGroupId = teacherGroup.id;

        const subjectTeacher = this.subjectTeachers.find(
          (item) => item.id == teacherGroup.subject_teacher_id
        );

        if (subjectTeacher) {
          // Si hay un registro obtiene el id y lo guarda en la variable subjectTeacherId para que en el segundo modal aparezca el maestro seleccionado
          const { id: subjectTeacherId } = subjectTeacher;
          this.subjectTeacherId = subjectTeacherId;
        }
      }
    },

    setSubjectTeacherOfTechnicalGroup() {
      // Busca el registro que tenga la llave foranea del grupo academico seleccionado y que el subject_teacher coincida con alguno de los de la asignatura
      const teacherGroup = this.teacherGroupsOfTechnicalGroups.find(
        (item) =>
          item.teacher_groupable_id == this.selectedTechnicalGroup.id &&
          this.subjectTeachers.some(
            (element) => item.subject_teacher_id == element.id
          )
      );
      if (teacherGroup) {
        // Si hay un registro, guarda su id y  busca el subject_teacher en los maestros que imparten la asignatura que coincida con el del registro obtenido
        this.teacherGroupId = teacherGroup.id;

        const subjectTeacher = this.subjectTeachers.find(
          (item) => item.id == teacherGroup.subject_teacher_id
        );

        if (subjectTeacher) {
          // Si hay un registro obtiene el id y lo guarda en la variable subjectTeacherId para que en el segundo modal aparezca el maestro seleccionado
          const { id: subjectTeacherId } = subjectTeacher;
          this.subjectTeacherId = subjectTeacherId;
        }
      }
    },

    selectAcademicGroup(academicGroup) {
      this.selectedAcademicGroup = academicGroup;
    },

    selectTechnicalGroup(technicalGroup) {
      this.selectedTechnicalGroup = technicalGroup;
    },

    teacherData(id) {
      return this.teachers.find((item) => item.id_user == id);
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      // If modal was open, make request for fill sections and sectionGroups
      if (newValue) {
        this.$store.dispatch(FETCH_USERS);
        this.getAcademicGroupsOfGrade(this.subject.grade_id);
        this.getTechnicalGroupsOfGrade(this.subject.grade_id);
      }
    },
    asignarDocenteGruposAcademicosDialog(newValue) {
      // If nested modal was open, make request for fill subjectTeachers
      if (newValue) {
        this.getTeacherGroupsOfAcademicGroups(this.selectedAcademicGroup.id);
      }
    },
    asignarDocentesGruposTecnicosDialog(newValue) {
      // If nested modal was open, make request for fill subjectTeachers
      if (newValue) {
        this.getTeacherGroupsOfTechnicalGroups(this.selectedTechnicalGroup.id);
      }
    },
  },
  computed: {
    teachers() {
      let userTypes = [];
      if (process.env.APP_ENV === "local") {
        userTypes.push("Superadministrador");
        userTypes.push("Superadmin");
      } else {
        userTypes.push("Docente académico");
        userTypes.push("Docente técnico");
        userTypes.push("Coordinación técnica"); // TODO: Cambiar por el tipo de usuario que corresponda a Eduardo barrera ya que se agregó para que el pudiera dar clases, pero no debería tener ese tipo de usuario
      }
      return this.$store.getters.getUsersByUserType(userTypes);
    },
  },
};
</script>
